<template>
  <svg width="468" height="352" viewBox="0 0 468 352" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M119.203 29.3809C151.599 80.3291 231.453 125.749 231.453 125.749C231.453 125.749 167.964 120.619 136.472 79.7218L147.901 70.2986C147.901 70.2986 138.825 71.1781 131.22 72.0576C124.203 60.4566 119.665 46.4055 119.203 29.3809Z" fill="#318FF1"/>
    <path d="M204.224 50.4686C199.287 52.6883 192.018 56.1016 192.018 56.1016L199.035 46.0502C190.862 39.7052 181.85 35.4752 172.942 32.7111C169.328 37.9672 165.085 43.8305 165.085 43.8305L164.244 30.4495C140.336 25.3191 119.223 29.4234 119.223 29.4234C151.618 80.3717 231.472 125.792 231.472 125.792C231.094 88.3081 219.665 64.9385 204.224 50.4686Z" fill="#02CCC0"/>
    <path d="M107.312 101.292C116.85 95.7008 126.514 92.7063 135.905 91.4708C138.742 94.6956 142.208 98.4858 142.208 98.4858L142.397 90.9054C178.511 89.1883 208.953 111.846 208.953 111.846C188.28 124.368 170.507 127.886 155.843 126.86C154.918 124.222 153.616 120.264 153.616 120.264L151.179 126.358C144.141 125.352 137.901 123.321 132.502 120.829C133.804 117.667 135.17 114.045 135.17 114.045L127.46 118.254C114.245 110.799 107.312 101.292 107.312 101.292Z" fill="#318FF1"/>
    <path d="M155.864 126.839C154.939 124.2 153.637 120.242 153.637 120.242L151.179 126.357C144.141 125.352 137.901 123.321 132.502 120.829C133.804 117.667 135.17 114.044 135.17 114.044L127.46 118.253C114.245 110.798 107.312 101.291 107.312 101.291C149.33 96.496 208.974 111.845 208.974 111.845C188.301 124.368 170.507 127.886 155.864 126.839Z" fill="#02CCC0"/>
    <path d="M128.676 118.357C128.676 118.378 128.676 118.378 128.655 118.399C128.529 118.776 128.403 119.153 128.298 119.53C128.256 119.655 128.214 119.781 128.193 119.928C128.151 120.074 128.13 120.2 128.088 120.346C128.046 120.493 128.025 120.64 127.983 120.765C127.962 120.891 127.92 121.037 127.899 121.163C127.878 121.31 127.836 121.477 127.815 121.624C127.794 121.749 127.773 121.896 127.752 122.022C127.731 122.189 127.71 122.357 127.689 122.524C127.668 122.65 127.668 122.776 127.647 122.88C127.626 123.069 127.605 123.257 127.605 123.467C127.605 123.571 127.584 123.676 127.584 123.781C127.562 124.074 127.563 124.367 127.563 124.681V262.47C127.563 262.784 127.562 263.119 127.584 263.433C127.626 264.061 127.689 264.689 127.773 265.297C127.815 265.611 127.878 265.904 127.941 266.218C129.558 274.071 136.155 280.122 144.285 280.96C144.916 281.023 145.546 281.065 146.197 281.065H376.222H428.701C436.769 281.065 443.638 275.955 446.223 268.815C446.244 268.773 446.874 266.553 446.916 266.406C446.937 266.281 447.336 262.805 447.336 262.491V124.681C447.336 124.032 447.294 123.404 447.231 122.776C446.349 114.022 439.374 107.07 430.592 106.191C429.962 106.128 429.332 106.086 428.68 106.086H198.677H146.197C138.13 106.086 131.26 111.195 128.676 118.357Z" fill="#333333"/>
    <path d="M152.969 115.097V272.025H421.943V115.097L152.969 115.097Z" fill="white"/>
    <path d="M144.518 160.699V226.159C144.518 226.62 144.139 226.997 143.677 226.997H141.997C141.534 226.997 141.156 226.62 141.156 226.159V160.699C141.156 160.238 141.534 159.861 141.997 159.861H143.677C144.139 159.861 144.518 160.238 144.518 160.699Z" fill="white"/>
    <path opacity="0.3" d="M434.899 185.953C439.164 185.953 442.63 189.408 442.63 193.659C442.63 197.91 439.164 201.365 434.899 201.365C430.634 201.365 427.168 197.91 427.168 193.659C427.168 189.408 430.634 185.953 434.899 185.953Z" fill="#DAEEF7"/>
    <path d="M438.114 191.355V195.941C438.114 196.444 437.694 196.862 437.189 196.862H432.588C432.084 196.862 431.664 196.444 431.664 195.941V191.355C431.664 190.852 432.084 190.434 432.588 190.434H437.189C437.715 190.455 438.114 190.852 438.114 191.355ZM431.937 195.962C431.937 196.339 432.231 196.632 432.609 196.632H437.21C437.589 196.632 437.883 196.339 437.883 195.962V191.376C437.883 190.999 437.589 190.706 437.21 190.706H432.609C432.231 190.706 431.937 190.999 431.937 191.376V195.962Z" fill="#DAEEF7"/>
    <path d="M406.035 115.095V272.023H421.939V115.095H406.035Z" fill="#C0EFFF"/>
    <path d="M152.973 115.101V272.029H167.238V115.101H152.973Z" fill="#C0EFFF"/>
    <path d="M127.521 200.739V238.201C127.521 238.201 97.7731 282.344 97.521 274.659C97.2689 266.974 90.0419 230.621 93.0461 226.956C96.0504 223.292 119.433 193.012 119.433 193.012L127.521 200.739Z" fill="#FC8D84"/>
    <path d="M172.88 180.467C150.211 181.43 149.308 219.207 135.694 234.074C128.929 241.466 122.627 241.99 117.795 240.44C110.442 238.074 106.933 229.719 110.105 222.683C114.98 211.898 127.501 202.622 127.501 202.622V175.65L89.2438 209.427C84.3068 213.783 80.9664 219.667 79.7899 226.138C70.357 277.652 88.0043 366.46 88.0043 366.46C88.0043 366.46 121.933 379.883 159.266 366.46L149.896 323.951C170.695 302.173 168.111 251.874 169.14 231.143C169.938 215.312 174.665 194.35 176.871 185.304C177.501 182.77 175.506 180.362 172.88 180.467Z" fill="#FFC0BB"/>
    <path opacity="0.3" d="M175.526 184.091L172.921 193.556C172.606 194.708 171.576 195.545 170.379 195.608C168.131 195.713 164.665 195.504 163.551 193.368C161.849 190.122 167.228 183.672 168.971 182.269C170.736 180.866 176.072 182.081 175.526 184.091Z" fill="#FF5B7E"/>
    <path d="M409.73 195.002C409.73 195.002 393.932 196.321 393.386 200.509C392.839 204.697 394.058 212.068 408.953 213.492H424.394C424.394 213.492 419.772 200.719 420.004 200.279C420.214 199.839 409.73 195.002 409.73 195.002Z" fill="#EFADAB"/>
    <path d="M384.372 257.404C382.229 258.534 381.494 261.236 382.713 263.309C389.982 275.81 418.89 325.46 421.474 329.502C424.457 334.13 431.747 345.019 455.928 350.631C480.109 356.243 490.046 372.43 490.046 372.43H564.606C564.606 372.43 547.043 344.684 532.463 272.69C532.463 272.69 521.202 212.905 499.983 214.894C499.983 214.894 487.063 201.346 474.479 204.005C474.479 204.005 450.613 189.808 437.041 189.808L404.415 151.361C401.179 147.55 395.486 146.817 391.515 149.832C390.234 150.817 389.267 152.094 388.994 153.79C388.007 160.072 396.284 175.924 409.541 195.064C422.797 214.224 455.592 274.994 441.348 301.735C441.348 301.735 426.768 290.846 414.184 273.339C403.658 258.702 391.242 253.76 384.372 257.404Z" fill="#FFC0BB"/>
    <path d="M437.166 189.809C441.494 193.494 445.549 197.515 449.393 201.724C451.326 203.818 453.154 205.996 454.981 208.194C456.788 210.393 458.511 212.676 460.192 214.979C461.032 216.131 461.83 217.324 462.629 218.497C463.406 219.691 464.204 220.884 464.94 222.099C466.431 224.528 467.86 227.02 469.099 229.596L469.582 230.559C469.729 230.873 469.877 231.208 470.024 231.522C470.318 232.171 470.633 232.82 470.885 233.47L471.683 235.459C471.935 236.129 472.166 236.799 472.419 237.469C472.671 238.139 472.86 238.83 473.07 239.521L473.364 240.547C473.469 240.882 473.532 241.238 473.637 241.574L473.889 242.621C473.973 242.977 474.057 243.312 474.12 243.668L474.477 245.762L474.372 245.783C473.784 243.018 472.944 240.296 471.914 237.658C470.906 235.019 469.687 232.443 468.385 229.931C467.734 228.674 467.04 227.439 466.347 226.203C465.612 224.989 464.919 223.753 464.141 222.58C463.406 221.366 462.608 220.193 461.809 219.021C461.011 217.848 460.213 216.675 459.372 215.544C456.074 210.938 452.502 206.519 448.784 202.226C446.914 200.09 445.023 197.975 443.069 195.923C441.116 193.871 439.141 191.84 437.082 189.871L437.166 189.809Z" fill="#EAA0A0"/>
    <path d="M399.436 272.773C400.088 269.423 396.18 264.125 392.651 260.125C390.445 257.633 386.936 256.691 383.869 257.738C382.146 259.015 381.621 261.403 382.734 263.308C384.688 266.659 388.197 272.711 392.356 279.83C395.424 278.26 398.827 275.873 399.436 272.773Z" fill="#EAA0A0"/>
    <path d="M402.568 166.458C404.459 166.081 413.009 163.254 412.484 161.684L404.059 151.758C402.379 149.79 400.047 148.638 397.631 148.408C397.61 148.408 397.61 148.408 397.589 148.408C397.526 148.408 397.358 148.387 397.148 148.387C396.013 148.387 393.24 148.701 392.61 151.758C392 154.774 397 162.103 399.227 165.139C399.984 166.186 401.286 166.71 402.568 166.458Z" fill="#EAA0A0"/>
    <path opacity="0.2" d="M490.069 372.43H564.629C564.629 372.43 553.116 354.232 540.994 309.105C535.679 342.338 506.33 340.433 494.691 342.275C481.287 344.39 429.228 333.585 421.938 330.172C425.173 335.114 432.862 345.291 455.972 350.651C480.132 356.243 490.069 372.43 490.069 372.43Z" fill="#FFC0BB"/>
    <path opacity="0.2" d="M413.492 201.115C428.114 224.527 454.333 278.302 441.35 301.755L449.963 310.236C449.963 310.236 471.728 286.217 437.106 233.217C429.06 220.883 421.034 210.224 413.492 201.115Z" fill="#FFC0BB"/>
    <path d="M182.103 173.431C182.103 173.578 181.935 173.662 181.809 173.578C180.884 172.908 177.943 170.814 177.712 171.002C177.439 171.233 180.023 173.18 179.918 173.494C179.813 173.808 177.355 171.861 177.103 172.154C176.851 172.447 179.162 174.143 179.12 174.311C179.057 174.478 176.662 173.075 176.409 173.159C176.157 173.243 178.531 175.044 178.51 175.253C178.489 175.462 176.451 174.771 176.367 174.939C176.283 175.106 176.199 175.211 180.359 178.101L182.754 179.797L183.91 179.316L184.876 177.305C184.876 177.305 182.838 171.128 182.586 171.044C182.334 170.981 182.208 171.149 182.103 173.431Z" fill="#FFC0BB"/>
    <path d="M208.343 174.708L204.309 173.787L200.885 174.185C199.771 178.771 196.032 181.074 195.149 181.619C193.28 182.791 183.931 175.337 183.931 175.337L182.25 179.253C182.25 179.253 192.901 191.293 198.07 188.173C203.238 185.074 208.028 175.818 208.028 175.818L208.343 174.708Z" fill="#FFC0BB"/>
    <path d="M244.98 167.799H261.304L262.06 175.254C262.06 175.254 256.766 176.657 256.262 176.322C255.779 175.987 246.955 173.83 246.955 173.641C246.955 173.432 242.375 172.469 242.585 172.343C242.795 172.238 242.921 167.799 242.921 167.799H244.98Z" fill="#FCB2AE"/>
    <path d="M268.449 161.746L269.29 169.138L268.827 169.724C265.823 173.494 261.075 175.441 256.285 174.897L258.008 162.27L268.449 161.746Z" fill="#FFC0BB"/>
    <path d="M256.914 170.27L258.007 162.229L268.448 161.727L268.91 165.81C266.998 167.443 263.154 169.893 256.914 170.27Z" fill="#F99893"/>
    <path d="M250.276 149.015C250.78 150.795 250.045 152.554 248.616 152.972C247.188 153.37 245.612 152.281 245.108 150.501C244.604 148.722 245.339 146.963 246.768 146.544C248.196 146.146 249.772 147.256 250.276 149.015Z" fill="#F99893"/>
    <path d="M249.689 159.025C249.353 158.25 249.058 157.433 248.869 156.575C248.281 154.292 248.134 151.926 248.155 149.895C248.155 149.455 248.134 149.015 248.113 148.575C247.987 146.795 248.197 144.974 248.827 143.11C250.802 137.163 256.601 133.142 262.882 133.352C271.37 133.624 277.609 140.995 276.706 149.162C277.651 148.764 278.66 148.764 279.5 149.245C281.118 150.188 281.475 152.554 280.298 154.564C279.122 156.575 276.874 157.433 275.256 156.512C275.025 156.386 274.836 156.219 274.668 156.051C273.197 162.92 266.895 167.673 260.046 166.835C256.685 166.417 253.806 164.741 251.748 162.312C251.6 162.166 251.474 161.998 251.306 161.81C250.634 160.951 250.109 160.009 249.689 159.025Z" fill="#FFC0BB"/>
    <path d="M263.259 149.706C263.175 150.397 263.659 151.004 264.352 151.088C265.045 151.172 265.654 150.69 265.759 149.999C265.844 149.308 265.36 148.701 264.667 148.617C263.974 148.554 263.344 149.036 263.259 149.706Z" fill="#333333"/>
    <path d="M252.482 149.057C252.398 149.748 252.881 150.377 253.575 150.46C254.268 150.544 254.898 150.062 254.982 149.371C255.066 148.68 254.583 148.052 253.89 147.968C253.196 147.906 252.566 148.387 252.482 149.057Z" fill="#333333"/>
    <path d="M262.082 147.068C262.712 146.901 263.322 146.775 263.931 146.712C264.54 146.649 265.107 146.67 265.675 146.754C266.242 146.859 266.809 147.026 267.334 147.298L267.544 147.382C267.607 147.424 267.67 147.466 267.733 147.487L268.133 147.717C268.406 147.885 268.658 148.073 268.952 148.241C268.805 147.948 268.637 147.654 268.427 147.403C268.196 147.173 267.985 146.88 267.712 146.691C267.187 146.251 266.515 145.979 265.822 145.854C265.128 145.728 264.414 145.791 263.763 146C263.111 146.23 262.523 146.586 262.082 147.068Z" fill="#333333"/>
    <path d="M257.041 146.711C256.663 146.145 256.117 145.727 255.529 145.412C254.919 145.098 254.226 144.952 253.512 144.973C252.819 144.994 252.104 145.161 251.516 145.517C251.201 145.664 250.949 145.915 250.697 146.103C250.466 146.334 250.255 146.606 250.066 146.857C250.382 146.732 250.655 146.585 250.949 146.459L251.39 146.292C251.453 146.271 251.537 146.229 251.6 146.208L251.81 146.145C252.377 145.936 252.966 145.873 253.533 145.852C254.1 145.831 254.688 145.915 255.277 146.062C255.844 146.229 256.432 146.438 257.041 146.711Z" fill="#333333"/>
    <path d="M258.993 146.711C259.161 147.59 259.182 148.512 259.098 149.412C259.056 149.873 258.972 150.313 258.846 150.773C258.699 151.213 258.531 151.674 258.153 152.093L258.006 152.239L257.859 152.365C257.796 152.428 257.712 152.47 257.67 152.532C257.565 152.658 257.481 152.784 257.439 152.93C257.333 153.223 257.418 153.579 257.586 153.894C257.964 154.564 258.741 155.003 259.581 155.318C258.699 155.359 257.691 155.087 257.018 154.27C256.703 153.873 256.493 153.307 256.619 152.721C256.661 152.574 256.703 152.449 256.766 152.323C256.829 152.197 256.913 152.072 256.997 151.967C257.081 151.841 257.186 151.779 257.291 151.674C257.354 151.632 257.375 151.611 257.396 151.569L257.481 151.485C257.712 151.234 257.88 150.857 258.027 150.48C258.195 150.103 258.279 149.684 258.405 149.266C258.636 148.449 258.804 147.59 258.993 146.711Z" fill="#F99893"/>
    <path d="M287.901 272.019C290.149 271.014 291.41 270.323 291.41 270.323L290.065 209.261C290.065 209.261 301.515 187.776 297.733 178.792C293.973 169.809 270.737 167.777 270.737 167.777C262.775 176.572 255.38 169.997 255.38 169.997C255.38 169.997 251.619 169.788 244.749 170.667C237.88 171.547 236.997 180.677 236.997 180.677L232.081 179.378L228.047 208.255L227.039 272.04H287.901V272.019Z" fill="#74BDC1"/>
    <path d="M248.343 128.996C251.116 127.321 256.263 125.771 261.515 125.603C260.276 124.787 259.519 124.117 259.834 123.886C262.335 122.002 270.549 126.671 273.175 128.242C273.658 127.593 275.339 125.562 277.125 126.462C279.247 127.53 275.276 129.289 275.276 129.289C275.276 129.289 278.133 129.582 278.805 131.069C279.31 132.179 277.566 131.99 276.62 131.823C276.915 131.906 277.335 132.116 277.776 132.493C277.776 132.493 281.768 135.655 281.41 137.54C281.326 138 280.948 138.042 280.402 137.854C280.906 139.78 281.726 143.424 281.032 143.926C280.633 144.199 280.255 143.989 279.94 143.654C279.457 146.607 278.679 148.931 278.679 148.931C275.255 148.408 272.44 152.114 272.44 152.114C273.175 147.528 269.688 138.922 269.688 138.922C269.688 138.922 256.683 144.513 258.805 140.66C258.805 140.66 243.553 145.979 246.683 138.587C246.683 138.587 240.843 138.251 247.649 132.409C247.67 132.43 243.385 132.011 248.343 128.996Z" fill="#333333"/>
    <path d="M213.173 166.332L218.425 165.746L228.468 166.018L230.421 170.625L227.27 173.306C227.27 173.306 225.358 175.086 222.921 174.646C220.505 174.206 225.863 175.002 220.106 174.018C214.35 173.033 213.278 173.389 213.362 172.342C213.446 171.295 212.921 170.521 213.005 169.599C213.089 168.636 213.173 166.332 213.173 166.332Z" fill="#FFC0BB"/>
    <path d="M241.893 212.715C241.893 212.715 239.772 210.998 239.309 211.186C238.847 211.375 229.351 211.626 229.372 214.642C229.393 217.657 231.41 228.902 231.41 228.902L223.532 232.65L222.208 234.786L221.746 241.843C221.746 241.843 222.334 244.524 222.418 244.628C222.502 244.733 223.868 245.089 223.868 245.089L227.292 238.639C229.918 238.179 239.057 236.357 241.914 232.985C245.255 229.049 241.893 212.715 241.893 212.715Z" fill="#FCB2AE"/>
    <path d="M230.045 229.531L223.532 232.63L222.208 234.766L221.746 241.823C221.746 241.823 222.334 244.504 222.418 244.608C222.502 244.713 223.868 245.069 223.868 245.069L227.292 238.619C228.196 238.452 229.876 238.138 231.809 237.656C230.507 234.745 230.129 231.625 230.045 229.531Z" fill="#FEE4CB"/>
    <path d="M223.867 245.069L222.879 246.849C222.438 247.666 221.156 247.435 221.072 246.514L219.98 233.782C219.938 233.342 220.295 232.923 220.757 232.882L223.53 232.609C221.43 241.383 222.543 244.231 223.867 245.069Z" fill="#FA5371"/>
    <path d="M232.628 212.715C232.628 212.715 230.506 210.998 230.044 211.186C229.582 211.375 220.086 211.626 220.107 214.642C220.128 217.657 222.144 228.902 222.144 228.902L214.266 232.65L212.943 234.786L212.48 241.843C212.48 241.843 213.069 244.524 213.153 244.628C213.237 244.733 214.602 245.089 214.602 245.089L218.027 238.639C220.653 238.179 229.792 236.357 232.649 232.985C235.968 229.049 232.628 212.715 232.628 212.715Z" fill="#FFC0BB"/>
    <path d="M220.759 229.531L214.247 232.63L212.923 234.766L212.461 241.823C212.461 241.823 213.049 244.504 213.133 244.608C213.217 244.713 214.583 245.069 214.583 245.069L218.007 238.619C218.911 238.452 220.591 238.138 222.524 237.656C221.222 234.745 220.843 231.625 220.759 229.531Z" fill="#FEE4CB"/>
    <path d="M214.581 245.069L213.594 246.849C213.153 247.666 211.871 247.435 211.787 246.514L210.695 233.782C210.653 233.342 211.01 232.923 211.472 232.882L214.245 232.609C212.165 241.383 213.258 244.231 214.581 245.069Z" fill="#FA5371"/>
    <path d="M213.173 166.333C212.08 166.458 210.925 166.668 209.811 166.919C205.421 167.966 197.773 178.436 197.773 178.436C197.773 178.436 199.034 184.488 206.387 186.059C206.387 186.059 200.904 216.778 216.135 220.401C235.316 224.966 250.442 213.7 250.442 213.7L242.942 203.628C242.942 203.628 236.745 190.791 237.018 180.677C237.018 180.677 237.711 172.217 244.245 170.751L245.001 167.777L228.467 165.977C228.467 165.977 228.635 172.447 221.996 172.594C213.929 172.803 213.173 166.333 213.173 166.333Z" fill="#FA5371"/>
    <g opacity="0.3">
    <path opacity="0.3" d="M239.811 195.753C212.458 194.937 208.593 176.781 208.593 176.781L206.387 186.058C206.387 186.058 200.903 216.778 216.135 220.4C235.316 224.965 250.442 213.699 250.442 213.699L242.942 203.627C242.942 203.627 241.345 200.339 239.811 195.753Z" fill="#FA5371"/>
    </g>
    <path d="M270.887 203.524C270.887 203.524 266.895 208.194 261.412 207.963C259.71 207.9 219.352 195.336 219.184 195.169C216.6 193.682 207.966 189.075 204.352 191.441C200.087 194.247 199.394 198.959 199.625 200.718C199.856 202.456 202.797 207.23 205.34 204.403C206.558 203.063 210.066 203.314 213.407 203.943C213.407 203.943 252.252 226.307 264.248 225.239C276.223 224.171 283.765 216.046 283.765 216.046C283.765 216.046 276.706 206.791 270.887 203.524Z" fill="#FFC0BB"/>
    <path d="M211.66 211.417C208.488 211.397 206.45 214.726 207.92 217.532C208.656 218.956 209.811 220.673 211.429 221.867C214.685 224.275 221.744 224.757 224.307 221.804C226.891 218.851 225.778 218.872 225.778 218.872C225.778 218.872 227.312 217.03 226.135 216.213C226.135 216.213 226.681 214.684 225.652 214.37C225.652 214.37 224.959 214.224 224.328 214.559C224.328 214.559 224.391 213.574 223.257 213.553C222.122 213.553 217.143 216.045 213.572 215.585C210.757 215.229 213.593 213.407 214.916 212.653C215.526 212.297 216.009 211.773 216.261 211.103C216.324 210.915 216.366 210.726 216.387 210.538C216.408 210.098 215.904 209.847 215.568 210.14C214.979 210.685 213.971 211.438 213.047 211.438C212.71 211.459 212.227 211.417 211.66 211.417Z" fill="#FFC0BB"/>
    <path d="M208.594 176.781C208.342 177.954 208.069 179.127 207.817 180.299C207.691 180.886 207.544 181.472 207.439 182.058C207.313 182.645 207.187 183.231 207.06 183.817L206.871 184.697L206.703 185.576C206.577 186.163 206.493 186.749 206.367 187.335C206.157 188.508 205.968 189.701 205.779 190.895C205.716 189.681 205.758 188.466 205.884 187.272C205.905 186.979 205.947 186.665 205.989 186.372C206.031 186.079 206.073 185.765 206.115 185.472C206.157 185.178 206.22 184.885 206.262 184.571L206.43 183.692C206.493 183.398 206.556 183.105 206.619 182.812C206.682 182.519 206.745 182.226 206.829 181.933C206.976 181.346 207.145 180.76 207.313 180.195C207.712 179.022 208.111 177.891 208.594 176.781Z" fill="white"/>
    <path d="M206.642 142.733C206.642 142.733 202.125 138.587 196.768 142.649C191.389 146.691 196.305 152.491 192.629 155.695C192.629 155.695 200.339 155.632 202.65 150.251C204.415 146.167 205.885 145.204 206.495 144.995C206.873 144.869 207.188 144.597 207.335 144.241C207.482 143.801 207.461 143.257 206.642 142.733Z" fill="#333333"/>
    <path d="M207.901 141.79C207.313 141.707 206.745 142.105 206.661 142.712L206.283 145.308C206.199 145.958 206.661 146.544 207.313 146.586C207.901 146.628 208.426 146.188 208.489 145.602L208.804 143.005C208.867 142.419 208.468 141.874 207.901 141.79Z" fill="#FEE4CB"/>
    <path d="M230.296 138.21C230.296 138.21 234.813 134.063 240.17 138.126C245.548 142.167 240.632 147.968 244.309 151.172C244.309 151.172 236.599 151.109 234.288 145.727C232.523 141.644 231.052 140.681 230.443 140.471C230.065 140.346 229.75 140.073 229.603 139.717C229.435 139.278 229.477 138.733 230.296 138.21Z" fill="#333333"/>
    <path d="M228.197 138.062C228.701 137.727 229.373 137.853 229.709 138.355L231.159 140.533C231.516 141.077 231.348 141.81 230.76 142.124C230.234 142.418 229.583 142.25 229.268 141.747L227.881 139.528C227.566 139.046 227.713 138.397 228.197 138.062Z" fill="#FEE4CB"/>
    <path d="M217.307 168.35H224.934V160.748H217.307V168.35Z" fill="#FFC0BB"/>
    <path d="M217.312 165.307V160.742H224.939V164.616C223.3 165.496 220.863 166.019 217.312 165.307Z" fill="#F99893"/>
    <path d="M208.973 152.03C208.973 152.03 205.549 143.507 210.444 139.466C215.339 135.424 221.074 135.047 225.192 136.513C229.331 137.979 232.566 144.575 231.369 148.763L218.049 146.711L208.973 152.03Z" fill="#333333"/>
    <path d="M229.918 150.544C229.624 151.801 230.233 152.973 231.262 153.204C232.292 153.413 233.384 152.575 233.678 151.34C233.972 150.084 233.363 148.911 232.334 148.681C231.283 148.471 230.212 149.309 229.918 150.544Z" fill="#F99893"/>
    <path d="M230.738 157.432C230.949 156.888 231.117 156.301 231.222 155.715C231.558 154.123 231.579 152.469 231.474 151.066C231.453 150.773 231.453 150.459 231.453 150.145C231.474 148.909 231.243 147.653 230.738 146.396C229.079 142.376 224.772 139.863 220.276 140.282C214.205 140.868 210.024 146.229 210.99 151.82C210.297 151.59 209.562 151.631 208.994 152.008C207.881 152.72 207.713 154.396 208.637 155.715C209.562 157.055 211.221 157.537 212.335 156.825C212.482 156.72 212.629 156.615 212.734 156.469C214.058 161.159 218.764 164.154 223.638 163.253C226.033 162.814 228.028 161.515 229.415 159.756C229.499 159.652 229.604 159.526 229.709 159.379C230.129 158.793 230.465 158.123 230.738 157.432Z" fill="#FFC0BB"/>
    <path d="M220.633 151.926C220.738 152.428 220.381 152.91 219.856 153.015C219.331 153.12 218.827 152.784 218.721 152.282C218.616 151.779 218.974 151.298 219.499 151.193C220.045 151.088 220.528 151.423 220.633 151.926Z" fill="#333333"/>
    <path d="M228.512 150.858C228.617 151.36 228.26 151.842 227.735 151.947C227.21 152.051 226.705 151.716 226.6 151.214C226.495 150.711 226.853 150.23 227.378 150.125C227.924 150.041 228.428 150.355 228.512 150.858Z" fill="#333333"/>
    <path d="M221.24 150.27C220.778 150.103 220.295 149.998 219.812 149.956C219.329 149.914 218.866 149.935 218.404 150.04C218.173 150.082 217.942 150.166 217.732 150.249C217.627 150.291 217.522 150.354 217.417 150.396C217.354 150.417 217.312 150.459 217.27 150.48C217.228 150.501 217.165 150.543 217.123 150.564C216.724 150.815 216.345 151.129 215.988 151.464C216.24 151.045 216.556 150.668 216.955 150.354C217.144 150.208 217.375 150.061 217.606 149.956C217.837 149.852 218.089 149.768 218.341 149.705C218.845 149.6 219.371 149.579 219.875 149.684C220.127 149.726 220.358 149.81 220.589 149.893C220.82 150.019 221.03 150.124 221.24 150.27Z" fill="#333333"/>
    <path d="M225.105 149.769C225.253 149.581 225.442 149.413 225.631 149.246C225.82 149.099 226.051 148.952 226.282 148.848C226.744 148.617 227.248 148.492 227.774 148.471C228.026 148.45 228.299 148.471 228.551 148.534C228.803 148.575 229.055 148.659 229.286 148.743C229.748 148.931 230.169 149.225 230.526 149.56C230.085 149.329 229.643 149.12 229.202 148.994C229.139 148.973 229.097 148.952 229.034 148.952C228.971 148.931 228.929 148.911 228.866 148.911C228.74 148.89 228.635 148.869 228.509 148.848C228.278 148.827 228.047 148.806 227.795 148.806C227.332 148.827 226.849 148.931 226.408 149.099C225.946 149.246 225.505 149.476 225.105 149.769Z" fill="#333333"/>
    <path d="M210.968 151.842C210.968 151.842 217.565 148.22 220.717 143.634C220.717 143.634 220.927 145.895 220.254 146.775C220.527 146.9 225.086 144.429 225.717 140.744C226.347 137.079 219.96 139.299 219.96 139.299C219.96 139.299 216.137 139.215 215.99 139.299C215.843 139.404 213.363 141.288 213.258 141.435C213.153 141.56 210.296 146.796 210.296 146.796L209.645 148.68L208.973 151.989L210.968 151.842Z" fill="#333333"/>
    <path d="M226.958 156.742C226.748 157.705 225.655 158.438 224.311 158.48C222.966 158.522 221.832 157.831 221.559 156.91L226.958 156.742Z" fill="white"/>
    <path d="M224.626 150.836C224.584 151.38 224.605 151.946 224.71 152.49C224.773 152.762 224.836 153.035 224.941 153.307C225.046 153.579 225.193 153.851 225.445 154.082L225.55 154.165L225.634 154.228C225.676 154.27 225.718 154.291 225.76 154.333C225.844 154.396 225.886 154.48 225.928 154.563C226.012 154.731 225.97 154.961 225.886 155.171C225.697 155.589 225.235 155.924 224.731 156.155C225.277 156.134 225.886 155.904 226.243 155.38C226.412 155.129 226.517 154.752 226.39 154.417C226.369 154.333 226.327 154.249 226.285 154.186C226.243 154.103 226.18 154.04 226.117 153.977C226.054 153.914 225.991 153.872 225.928 153.809C225.886 153.789 225.886 153.768 225.865 153.747L225.802 153.705C225.655 153.558 225.529 153.349 225.403 153.118C225.277 152.888 225.214 152.637 225.109 152.386C224.941 151.883 224.794 151.359 224.626 150.836Z" fill="#F99893"/>
    <path d="M311.096 235.856C311.201 235.877 311.327 235.772 311.369 235.647C311.537 235.06 311.81 233.511 310.423 232.736C310.339 232.694 310.213 232.757 310.15 232.861C310.087 232.966 310.087 233.071 310.171 233.134C311.411 233.846 311.159 235.186 311.012 235.688C310.97 235.751 311.012 235.835 311.096 235.856Z" fill="white"/>
    <path d="M370.698 161.789C370.845 161.789 370.95 161.642 370.866 161.516C370.299 160.511 368.534 157.349 368.744 157.14C369.017 156.909 370.656 159.695 370.992 159.632C371.328 159.569 369.647 156.889 369.962 156.679C370.278 156.47 371.727 158.962 371.895 158.92C372.063 158.878 370.929 156.344 371.055 156.093C371.181 155.862 372.715 158.417 372.925 158.417C373.135 158.417 372.673 156.302 372.841 156.239C373.009 156.177 373.135 156.114 375.572 160.574L377 163.15L376.391 164.239L374.269 164.993C374.269 164.993 368.282 162.27 368.24 161.998C368.219 161.747 368.408 161.621 370.698 161.789Z" fill="#FFC0BB"/>
    <path d="M369.059 188.09L365.613 180.467L367.798 179.755C372.525 179.169 376.811 176.53 377.462 175.714C378.849 173.976 372.399 163.819 372.399 163.819L376.517 162.584C376.517 162.584 387.399 174.541 383.702 179.336C380.004 184.132 370.193 187.88 370.193 187.88L369.059 188.09Z" fill="#FFC0BB"/>
    <path d="M349.606 175.212L350.531 181.913C350.531 181.913 345.531 185.368 342.484 181.431L342.989 174.5L349.606 175.212Z" fill="#FFC0BB"/>
    <path d="M338.429 164.197C339.038 165.83 338.45 167.526 337.148 168.008C335.845 168.49 334.29 167.547 333.702 165.935C333.093 164.301 333.681 162.605 334.984 162.124C336.286 161.621 337.841 162.563 338.429 164.197Z" fill="#F99893"/>
    <path d="M342.672 178.876L342.987 174.479L349.626 175.19L350.004 178.017C348.743 178.541 346.369 179.169 342.672 178.876Z" fill="#F99893"/>
    <path d="M336.599 168.846C336.473 166.898 336.305 164.951 336.452 163.234C336.473 162.878 336.494 162.501 336.494 162.124C336.515 160.637 336.788 159.108 337.376 157.601C339.288 152.722 344.015 149.601 348.889 150.041C355.444 150.628 359.835 157.035 358.637 163.82C359.393 163.527 360.192 163.569 360.801 164.008C361.998 164.867 362.124 166.856 361.095 168.49C360.066 170.123 358.259 170.751 357.061 169.893C356.893 169.767 356.746 169.641 356.62 169.474C355.066 175.149 348.595 177.976 343.343 176.992C340.78 176.51 336.998 174.898 336.599 168.846Z" fill="#FFC0BB"/>
    <path d="M343.366 163.297C343.408 163.841 343.387 164.386 343.282 164.93C343.24 165.202 343.156 165.475 343.051 165.726C342.946 165.998 342.798 166.249 342.567 166.48L342.462 166.564L342.378 166.626C342.336 166.668 342.294 166.689 342.252 166.71C342.168 166.773 342.126 166.857 342.084 166.941C342 167.108 342.042 167.317 342.126 167.527C342.315 167.946 342.777 168.26 343.261 168.49C342.714 168.469 342.126 168.26 341.769 167.736C341.601 167.485 341.496 167.129 341.622 166.794C341.643 166.71 341.685 166.626 341.727 166.564C341.769 166.48 341.832 166.417 341.895 166.354C341.958 166.291 342.021 166.249 342.084 166.208C342.126 166.187 342.126 166.166 342.147 166.145L342.21 166.103C342.357 165.956 342.483 165.747 342.588 165.537C342.714 165.307 342.777 165.077 342.883 164.826C343.051 164.323 343.198 163.799 343.366 163.297Z" fill="#F99893"/>
    <path d="M346.581 164.407C346.455 164.972 346.791 165.537 347.358 165.663C347.926 165.789 348.493 165.454 348.619 164.888C348.745 164.323 348.409 163.757 347.842 163.632C347.274 163.506 346.707 163.841 346.581 164.407Z" fill="#333333"/>
    <path d="M339.081 163.213C338.955 163.779 339.291 164.344 339.858 164.47C340.426 164.595 340.993 164.26 341.119 163.695C341.245 163.129 340.909 162.564 340.342 162.438C339.795 162.313 339.228 162.648 339.081 163.213Z" fill="#333333"/>
    <path d="M346.391 161.935C346.937 161.809 347.441 161.725 347.945 161.683C348.449 161.642 348.933 161.663 349.395 161.767C349.857 161.872 350.319 161.998 350.76 162.249C350.992 162.333 351.202 162.5 351.433 162.626C351.664 162.751 351.874 162.919 352.126 163.066C352.021 162.814 351.874 162.563 351.727 162.333C351.538 162.123 351.37 161.893 351.139 161.725C350.697 161.348 350.151 161.097 349.563 160.971C348.975 160.846 348.365 160.888 347.819 161.055C347.294 161.223 346.769 161.516 346.391 161.935Z" fill="#333333"/>
    <path d="M327.546 178.268L312.398 176.719L313.407 180.488L331.117 184.886L327.546 178.268Z" fill="#FCB2AE"/>
    <path d="M348.094 155.423C340.216 155.277 340.258 150.502 340.258 150.502L340.468 150.712C341.056 151.089 342.296 151.193 342.296 151.193C340.93 147.822 345.006 147.131 345.006 147.131C345.006 147.131 342.296 149.832 348.409 149.832C354.523 149.832 355.888 151.863 355.888 151.863C355.972 152.052 355.993 152.219 355.993 152.387C355.993 152.387 356.077 152.701 355.909 153.12C355.783 153.476 355.573 153.811 355.342 154.104C355.027 154.46 354.67 154.795 354.271 155.046C353.472 155.57 352.569 155.905 351.645 156.114C351.413 156.177 351.182 156.198 350.951 156.24C350.72 156.261 350.489 156.303 350.258 156.324L349.565 156.366C349.334 156.366 349.102 156.387 348.871 156.366C348.409 156.345 347.947 156.324 347.485 156.282C347.023 156.219 346.56 156.156 346.119 156.052C346.581 156.01 347.044 155.989 347.506 155.968C347.737 155.968 347.968 155.926 348.199 155.926C348.43 155.905 348.661 155.884 348.871 155.884C349.334 155.842 349.775 155.779 350.216 155.737L350.888 155.633C351.119 155.591 351.329 155.57 351.54 155.507L352.191 155.36L352.821 155.151C352.989 155.109 353.136 155.046 353.304 154.984C352.086 155.277 350.426 155.465 348.094 155.423Z" fill="#333333"/>
    <path d="M340.257 159.861C339.753 159.61 339.144 159.463 338.555 159.484C337.967 159.505 337.379 159.652 336.875 159.966C336.812 159.987 336.749 160.029 336.707 160.071C336.665 160.301 336.644 160.531 336.602 160.762C336.791 160.699 336.959 160.615 337.148 160.573C337.61 160.406 338.093 160.343 338.576 160.322C339.06 160.301 339.543 160.364 340.026 160.469C340.509 160.594 341.013 160.762 341.518 160.971C341.223 160.49 340.761 160.134 340.257 159.861Z" fill="#333333"/>
    <path d="M348.113 168.908C347.609 169.599 347.021 170.165 346.286 170.458C345.929 170.604 345.529 170.688 345.13 170.688C344.92 170.709 344.731 170.667 344.521 170.667C344.311 170.646 344.122 170.604 343.891 170.583C344.08 170.688 344.269 170.772 344.479 170.856C344.689 170.897 344.899 170.981 345.109 170.981C345.55 171.044 345.992 170.96 346.412 170.814C346.832 170.646 347.189 170.374 347.483 170.06C347.798 169.683 348.008 169.306 348.113 168.908Z" fill="#F99893"/>
    <path d="M295.695 140.764C295.695 140.764 286.703 151.422 283.258 157.349C279.434 163.924 272.964 178.247 285.422 184.885C293.153 189.011 306.535 187.691 309.33 184.906C312.124 182.121 318.784 183.231 317.292 154.019C317.292 154.019 299.077 143.758 298.888 143.695C298.678 143.633 295.695 140.764 295.695 140.764Z" fill="#333333"/>
    <path opacity="0.3" d="M287.904 272.018C290.152 271.013 291.412 270.322 291.412 270.322L290.068 209.26C290.068 209.26 278.303 263.014 227.399 250.198L227.062 272.018H287.904Z" fill="#74BDC1"/>
    <path d="M292.504 180.488C292.504 180.488 312.42 178.729 320.971 179.693C320.971 179.693 321.139 185.053 320.803 185.326C320.445 185.598 316.601 193.388 316.286 193.639C315.971 193.911 311.118 195.063 311.118 195.063C311.118 195.063 308.071 193.723 306.769 193.283C305.466 192.843 302.672 191.754 301.874 190.77C301.075 189.786 295.508 186.666 295.235 186.666C294.941 186.666 292.504 180.488 292.504 180.488Z" fill="#FFC0BB"/>
    <path d="M366.662 177.996C362.965 178.331 351.578 180.509 351.578 180.509C345.443 184.048 341.536 179.336 341.536 179.336C339.309 179.148 327.502 177.996 327.502 177.996L327.691 182.896L324.456 184.174L322.922 203.229V214.098C339.12 218.788 359.456 214.768 359.456 214.768C360.191 204.695 359.099 192.215 359.099 192.215C366.179 191.503 369.498 189.262 369.498 189.262C369.813 185.514 366.662 177.996 366.662 177.996Z" fill="#318FF1"/>
    <path opacity="0.3" d="M366.665 177.996C364.375 178.205 359.143 179.106 355.509 179.776C353.492 187.796 345.404 202.79 323.071 201.701L322.945 203.229V214.098C339.143 218.788 359.48 214.768 359.48 214.768C360.215 204.695 359.122 192.215 359.122 192.215C366.202 191.503 369.522 189.262 369.522 189.262C369.816 185.514 366.665 177.996 366.665 177.996Z" fill="#318FF1"/>
    <path d="M288.784 211.376L287.523 213.491C286.725 214.81 285.948 216.15 285.17 217.491L284.561 216.653L284.162 216.108L283.742 215.585L282.922 214.538L282.061 213.512L281.641 213.009L281.2 212.507L280.296 211.523C279.687 210.894 279.078 210.245 278.448 209.617C277.187 208.36 275.885 207.167 274.519 206.015C273.237 204.926 271.935 203.879 270.548 202.916C270.779 202.581 271.011 202.246 271.242 201.89L272.019 200.592L272.733 199.251C272.964 198.812 273.195 198.351 273.406 197.911C274.288 196.11 275.128 194.268 275.906 192.425C276.095 191.964 276.305 191.503 276.494 191.043C276.683 190.582 276.872 190.121 277.061 189.64L277.607 188.237L278.153 186.834C278.847 184.949 279.561 183.064 280.191 181.18C279.456 183.043 278.7 184.907 277.922 186.75C277.145 188.593 276.347 190.435 275.527 192.257C274.708 194.079 273.847 195.901 272.943 197.681C272.04 199.461 271.074 201.22 269.918 202.811L269.75 203.063L270.002 203.23C271.431 204.235 272.796 205.345 274.141 206.476C275.485 207.607 276.788 208.8 278.048 210.015C278.679 210.622 279.288 211.25 279.918 211.879L280.822 212.842L281.263 213.323L281.704 213.826L282.565 214.81L283.406 215.836L283.826 216.339L284.225 216.862L285.023 217.909L285.212 218.14L285.36 217.888C286.158 216.444 286.935 214.999 287.712 213.554L288.889 211.376C289.288 210.643 289.666 209.931 290.044 209.198C289.645 209.973 289.204 210.664 288.784 211.376Z" fill="white"/>
    <path d="M355.401 272.02C349.162 255.853 341.578 238.787 336.052 234.704C324.918 226.474 325.002 209.24 325.002 209.24C325.002 209.24 333.09 189.053 328.51 183.692C326.662 181.536 323.762 180.342 320.947 179.672C319.96 183.63 317.334 191.817 312.712 191.461C307.796 191.105 296.64 183.42 292.501 180.447C288.636 181.661 284.917 183.818 284.119 187.671C282.375 196.194 294.35 232.589 294.35 232.589C294.35 232.589 286.619 251.393 280.484 271.978H355.401V272.02Z" fill="#FEB546"/>
    <path opacity="0.3" d="M280.484 272.019H315.611C309.771 257.131 312.67 238.912 312.67 238.912C290.043 219.186 292.501 180.488 292.501 180.488C288.636 181.703 284.917 183.86 284.119 187.713C282.375 196.236 294.35 232.63 294.35 232.63C294.35 232.63 286.619 251.435 280.484 272.019Z" fill="#FEB546"/>
    <path d="M288.427 235.184L298.931 243.791C299.078 243.916 299.309 243.895 299.435 243.749L299.918 243.162C300.044 243.016 300.023 242.785 299.876 242.66L289.372 234.053C289.225 233.928 288.994 233.948 288.868 234.095L288.384 234.681C288.237 234.828 288.279 235.058 288.427 235.184Z" fill="white"/>
    <path d="M302.523 239.017L300.275 242.493C300.002 242.912 299.498 243.1 299.203 242.87L289.434 234.87C289.182 234.661 289.056 234.305 289.182 233.886C289.308 233.467 289.665 232.965 290.632 232.693C292.523 232.148 295.569 234.117 297.838 234.263C297.922 234.263 298.006 234.305 298.069 234.347L302.523 237.991C302.775 238.179 302.775 238.619 302.523 239.017Z" fill="#02CCC0"/>
    <path d="M297.147 236.672C297.252 236.693 297.378 236.589 297.42 236.463C297.588 235.877 297.861 234.327 296.474 233.552C296.39 233.51 296.264 233.573 296.201 233.678C296.138 233.783 296.138 233.887 296.222 233.95C297.462 234.662 297.21 236.002 297.063 236.484C297 236.568 297.042 236.651 297.147 236.672Z" fill="white"/>
    <path d="M295.779 236.233C295.884 236.254 296.01 236.149 296.052 236.023C296.221 235.437 296.494 233.888 295.107 233.113C295.023 233.071 294.897 233.134 294.834 233.238C294.771 233.343 294.771 233.448 294.855 233.511C296.095 234.223 295.842 235.563 295.695 236.044C295.653 236.149 295.695 236.233 295.779 236.233Z" fill="white"/>
    <path opacity="0.3" d="M297.085 240.419C299.333 239.079 300.698 239.477 301.434 240.022C301.602 240.147 301.644 240.378 301.518 240.545L300.257 242.472C299.984 242.89 299.48 243.079 299.186 242.849L297.001 241.069C296.812 240.901 296.833 240.566 297.085 240.419Z" fill="#E87B50"/>
    <path d="M318.68 247.686L332.147 249.634C332.336 249.655 332.525 249.529 332.567 249.32L332.672 248.566C332.693 248.377 332.567 248.189 332.357 248.147L318.891 246.2C318.701 246.179 318.512 246.304 318.47 246.514L318.365 247.268C318.344 247.477 318.491 247.644 318.68 247.686Z" fill="white"/>
    <path d="M332.734 243.686L332.608 247.812C332.587 248.314 332.251 248.733 331.893 248.691L319.393 246.869C319.078 246.827 318.784 246.576 318.658 246.157C318.532 245.738 318.595 245.131 319.267 244.398C320.591 242.974 324.225 243.079 326.263 242.011C326.326 241.969 326.41 241.948 326.494 241.969L332.188 242.807C332.524 242.849 332.734 243.226 332.734 243.686Z" fill="#02CCC0"/>
    <path d="M326.894 244.462C326.999 244.42 327.041 244.274 327.02 244.148C326.852 243.562 326.284 242.096 324.688 242.138C324.604 242.138 324.52 242.263 324.52 242.389C324.52 242.515 324.583 242.598 324.667 242.598C326.095 242.556 326.578 243.855 326.704 244.336C326.746 244.441 326.831 244.483 326.894 244.462Z" fill="white"/>
    <path d="M325.53 244.796C325.635 244.754 325.677 244.608 325.656 244.482C325.488 243.896 324.921 242.43 323.324 242.472C323.24 242.472 323.156 242.597 323.156 242.723C323.156 242.849 323.219 242.932 323.303 242.932C324.732 242.89 325.215 244.189 325.341 244.67C325.362 244.775 325.446 244.817 325.53 244.796Z" fill="white"/>
    <path d="M324.249 245.11C324.354 245.069 324.396 244.922 324.375 244.796C324.207 244.21 323.64 242.744 322.043 242.786C321.959 242.786 321.875 242.912 321.875 243.037C321.875 243.163 321.938 243.247 322.022 243.247C323.451 243.205 323.934 244.503 324.06 244.985C324.081 245.089 324.165 245.131 324.249 245.11Z" fill="white"/>
    <path opacity="0.3" d="M328.806 247.707C330.046 245.403 331.411 245.047 332.336 245.11C332.546 245.131 332.693 245.319 332.693 245.529L332.63 247.832C332.609 248.335 332.273 248.754 331.915 248.712L329.121 248.314C328.806 248.251 328.68 247.937 328.806 247.707Z" fill="#E87B50"/>
    <path d="M311.052 219.395C311.052 219.395 300.086 228.253 297.48 232.692C297.48 232.692 298.72 237.132 303.237 239.728C303.237 239.728 315.086 229.656 316.43 229.049C317.775 228.441 326.599 228.169 326.599 228.169C326.599 228.169 336.893 217.908 336.893 217.825C336.893 217.741 336.284 213.595 335.821 213.595C335.359 213.595 318.93 212.652 318.93 212.652L313.006 217.427L311.052 219.395Z" fill="#232222"/>
    <path d="M359.48 214.769C359.48 214.769 361.686 225.029 351.643 226.642C341.601 228.254 337.631 228.589 337.631 228.589L334.122 244.211C334.122 244.211 328.996 243.75 324.689 241.886C324.689 241.886 320.593 221.281 334.248 214.433C347.946 207.586 359.48 214.769 359.48 214.769Z" fill="#333333"/>
    <path d="M358.955 196.299C356.14 194.435 349.375 198.058 347.358 199.21C347.232 199.335 315.677 209.156 314.332 209.198C310.845 209.345 308.114 206.937 307.231 206.057C306.034 207.691 302.462 212.151 297.315 214.915C297 215.292 296.832 215.522 296.832 215.522C296.832 215.522 302.735 221.867 312.105 222.705C321.475 223.543 351.854 206.057 351.854 206.057C354.48 205.555 357.211 205.366 358.177 206.413C360.173 208.633 362.484 204.905 362.652 203.544C362.82 202.183 362.274 198.498 358.955 196.299Z" fill="#FFC0BB"/>
    <path d="M308.261 204.675C307.19 203.23 306.118 201.785 305.026 200.34C303.934 198.895 302.841 197.471 301.728 196.047C300.614 194.623 299.501 193.22 298.345 191.817C297.19 190.435 296.055 189.032 294.816 187.713C295.971 189.095 297.085 190.519 298.177 191.943C299.27 193.388 300.341 194.812 301.412 196.278C302.484 197.722 303.555 199.167 304.606 200.633C305.635 202.036 306.665 203.439 307.673 204.863C306.98 206.099 306.076 207.209 305.11 208.256C304.606 208.8 304.06 209.323 303.513 209.826C302.967 210.329 302.4 210.81 301.812 211.292C300.656 212.234 299.438 213.114 298.198 213.951C297.022 214.747 295.803 215.501 294.585 216.192L294.396 215.941C294.291 215.794 294.186 215.648 294.08 215.501C293.891 215.187 293.681 214.894 293.513 214.58L292.967 213.637L292.442 212.674C291.769 211.376 291.118 210.077 290.509 208.758C289.9 207.439 289.29 206.12 288.681 204.779C288.093 203.439 287.505 202.099 286.938 200.759C287.442 202.141 287.967 203.502 288.492 204.842C289.038 206.203 289.585 207.544 290.173 208.884C290.761 210.224 291.37 211.543 292.022 212.862L292.526 213.847L293.051 214.81C293.219 215.124 293.429 215.438 293.618 215.752C293.702 215.92 293.828 216.066 293.933 216.213L294.249 216.674L294.375 216.862L294.585 216.757C295.929 216.024 297.211 215.25 298.471 214.412C299.732 213.574 300.971 212.695 302.148 211.732C303.324 210.789 304.459 209.763 305.509 208.653C306.56 207.544 307.505 206.329 308.24 204.989L308.324 204.821L308.261 204.675Z" fill="white"/>
    <path d="M355.32 220.506C358.429 221.113 359.753 224.799 357.757 227.249C356.749 228.484 355.278 229.95 353.429 230.788C349.753 232.484 342.757 231.542 340.824 228.149C338.891 224.757 339.963 224.966 339.963 224.966C339.963 224.966 338.849 222.872 340.152 222.286C340.152 222.286 339.921 220.674 341.013 220.59C341.013 220.59 341.728 220.59 342.274 221.03C342.274 221.03 342.4 220.045 343.534 220.276C344.648 220.506 349.018 223.94 352.61 224.213C355.446 224.422 353.03 222.077 351.875 221.071C351.35 220.611 350.971 219.983 350.866 219.292C350.845 219.103 350.824 218.894 350.845 218.705C350.908 218.265 351.476 218.14 351.728 218.496C352.19 219.145 353.03 220.087 353.934 220.276C354.27 220.339 354.753 220.401 355.32 220.506Z" fill="#FFC0BB"/>
    <path d="M302.648 170.813L302.817 182.373L312.796 184.885L314.497 182.205L314.035 171.421L303.867 167.84L302.648 170.813Z" fill="#FFC0BB"/>
    <path d="M293.66 155.549C293.66 155.549 290.698 143.445 297.756 138.901C304.815 134.378 312.273 134.88 317.379 137.582C322.463 140.304 325.509 149.769 323.24 155.172L306.349 149.999L293.66 155.549Z" fill="#333333"/>
    <path d="M302.461 168.992C302.461 168.992 302.671 171.023 302.839 173.578C306.495 177.557 313.343 176.196 314.247 175.819C314.184 174.541 314.247 171.966 314.247 171.966L302.461 168.992Z" fill="#F99893"/>
    <path d="M321.034 157.328C320.425 158.961 321.013 160.658 322.316 161.139C323.618 161.621 325.173 160.679 325.761 159.066C326.37 157.433 325.782 155.737 324.48 155.255C323.177 154.773 321.622 155.716 321.034 157.328Z" fill="#F99893"/>
    <path d="M320.888 166.751C321.266 166.039 321.581 165.306 321.833 164.51C322.527 162.416 322.842 160.217 322.968 158.291C322.989 157.872 323.052 157.474 323.094 157.055C323.325 155.401 323.262 153.663 322.821 151.883C321.371 146.166 316.245 141.999 310.341 141.769C302.379 141.455 296.035 147.925 296.308 155.652C295.446 155.213 294.501 155.15 293.682 155.548C292.106 156.322 291.623 158.521 292.568 160.49C293.535 162.458 295.572 163.421 297.148 162.646C297.358 162.542 297.568 162.395 297.736 162.249C298.64 168.803 304.207 173.682 310.678 173.347C313.85 173.18 316.665 171.797 318.745 169.662C318.892 169.536 319.039 169.389 319.186 169.222C319.879 168.489 320.426 167.651 320.888 166.751Z" fill="#FFC0BB"/>
    <path d="M308.785 157.516C308.827 158.207 308.281 158.815 307.588 158.836C306.894 158.877 306.285 158.333 306.264 157.642C306.222 156.951 306.768 156.344 307.462 156.323C308.155 156.281 308.743 156.804 308.785 157.516Z" fill="#333333"/>
    <path d="M320.004 157.516C320.046 158.207 319.5 158.815 318.806 158.835C318.113 158.856 317.504 158.333 317.483 157.642C317.441 156.951 317.987 156.344 318.68 156.323C319.374 156.281 319.983 156.804 320.004 157.516Z" fill="#333333"/>
    <path d="M309.837 155.401C309.27 155.087 308.661 154.878 308.051 154.731C307.442 154.584 306.812 154.522 306.203 154.584C305.888 154.605 305.593 154.668 305.299 154.752C305.152 154.794 305.005 154.836 304.858 154.899C304.795 154.919 304.711 154.961 304.648 154.982C304.585 155.003 304.501 155.045 304.438 155.066C303.871 155.338 303.346 155.694 302.82 156.071C303.198 155.548 303.703 155.087 304.27 154.752C304.564 154.584 304.858 154.438 305.173 154.333C305.488 154.228 305.825 154.145 306.161 154.124C306.833 154.061 307.526 154.145 308.157 154.375C308.472 154.48 308.766 154.626 309.06 154.794C309.333 154.982 309.606 155.171 309.837 155.401Z" fill="#333333"/>
    <path d="M314.941 155.402C315.173 155.171 315.446 154.983 315.719 154.815C316.013 154.648 316.307 154.501 316.622 154.396C317.252 154.166 317.925 154.082 318.618 154.145C318.954 154.166 319.29 154.25 319.605 154.355C319.921 154.459 320.236 154.606 320.509 154.773C321.076 155.129 321.58 155.569 321.958 156.093C321.433 155.695 320.908 155.339 320.341 155.088C320.278 155.046 320.194 155.025 320.131 155.004C320.068 154.983 319.984 154.941 319.921 154.92C319.773 154.878 319.626 154.815 319.479 154.773C319.185 154.69 318.87 154.627 318.576 154.606C317.967 154.543 317.336 154.606 316.727 154.752C316.118 154.878 315.509 155.108 314.941 155.402Z" fill="#333333"/>
    <path d="M313.448 156.93C313.805 157.495 314.099 158.102 314.394 158.71C314.667 159.317 314.919 159.945 315.108 160.615C315.192 160.95 315.276 161.285 315.297 161.641C315.297 161.725 315.297 161.83 315.297 161.914C315.297 161.955 315.276 162.018 315.276 162.06C315.255 162.123 315.234 162.165 315.192 162.228C315.171 162.249 315.15 162.29 315.129 162.311L315.066 162.353C315.024 162.374 315.003 162.416 314.961 162.437C314.877 162.479 314.814 162.542 314.73 162.584C314.436 162.772 314.12 162.898 313.784 163.002C313.469 163.107 313.133 163.212 312.797 163.254C313.406 162.961 314.015 162.688 314.541 162.311C314.604 162.27 314.667 162.207 314.73 162.165L314.814 162.081L314.856 162.039C314.856 162.039 314.856 162.018 314.877 162.018C314.919 161.955 314.919 161.788 314.898 161.641C314.877 161.327 314.793 161.013 314.709 160.699C314.541 160.071 314.31 159.443 314.099 158.814C313.868 158.207 313.658 157.579 313.448 156.93Z" fill="#F99893"/>
    <path d="M294.585 153.872L294.9 153.83L295.11 151.213L296.286 148.784C296.286 148.784 300.908 142.25 301.055 142.083C301.223 141.915 304.732 139.821 304.963 139.716C305.173 139.612 310.11 140.428 310.11 140.428C310.11 140.428 318.787 138.565 317.316 143.402C315.845 148.239 309.522 150.752 309.186 150.522C310.215 149.433 310.32 146.375 310.32 146.375C305.446 151.987 296.286 155.673 296.286 155.673C296.286 155.673 295.32 154.94 293.66 155.568L294.585 153.872Z" fill="#333333"/>
    <path d="M262.902 156.846C262.608 158.144 261.138 159.149 259.31 159.212C257.482 159.275 255.948 158.332 255.57 157.076L262.902 156.846Z" fill="white"/>
    <path d="M316.475 164.489C316.118 165.766 314.584 166.709 312.778 166.688C310.95 166.646 309.458 165.662 309.164 164.363L316.475 164.489Z" fill="white"/>
    <path d="M233.468 321.502C232.754 318.445 230.023 316.162 226.724 316.162C226.115 316.162 225.527 316.246 224.981 316.392C223.363 312.937 219.834 310.55 215.758 310.55C214.708 310.55 213.678 310.718 212.733 311.011C210.527 307.388 206.535 304.959 201.976 304.959C196.556 304.959 191.934 308.393 190.169 313.189C188.93 312.498 187.501 312.1 185.989 312.1C181.241 312.1 177.375 315.953 177.375 320.685C177.375 320.957 177.396 321.251 177.417 321.523H233.468V321.502Z" fill="white"/>
    <path d="M385.95 335.762C385.047 331.846 381.518 328.914 377.316 328.914C376.538 328.914 375.782 329.019 375.068 329.207C372.988 324.768 368.471 321.71 363.24 321.71C361.895 321.71 360.593 321.92 359.353 322.297C356.538 317.648 351.412 314.549 345.572 314.549C338.618 314.549 332.693 318.946 330.445 325.103C328.849 324.223 327.021 323.721 325.088 323.721C318.996 323.721 314.059 328.642 314.059 334.715C314.059 335.071 314.08 335.427 314.101 335.783H385.95V335.762Z" fill="white"/>
    <path d="M562 82.933C559.097 70.3925 547.757 61.004 534.256 61.004C531.758 61.004 529.328 61.3392 527.033 61.9428C520.35 47.7258 505.836 37.935 489.028 37.935C484.707 37.935 480.522 38.6055 476.539 39.8126C467.494 24.925 451.023 15 432.257 15C409.913 15 390.876 29.0829 383.653 48.7988C378.523 45.9823 372.65 44.3728 366.44 44.3728C346.864 44.3728 331 60.1322 331 79.5799C331 80.7199 331.067 81.86 331.135 83H562V82.933Z" fill="white"/>
    <path d="M468.346 108.244C468.346 108.244 467.989 111.804 465.152 112.055C467.968 112.307 468.346 115.867 468.346 115.867C468.346 115.867 468.703 112.307 471.539 112.055C468.703 111.825 468.346 108.244 468.346 108.244Z" fill="white"/>
    <path d="M103.088 151.465C103.088 151.465 101.87 163.506 92.332 164.343C101.87 165.181 103.088 177.222 103.088 177.222C103.088 177.222 104.307 165.181 113.845 164.343C104.307 163.527 103.088 151.465 103.088 151.465Z" fill="white"/>
  </svg>
</template>